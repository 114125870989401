import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './config/routes';
import headerBG from './assets/Header-BG.png';
import './App.css';

const bgStyle = { backgroundImage: `url(${headerBG})` };
class App extends Component {
  renderRoute = ({ key, path, component, render }) => (
    <Route key={key} path={path} exact component={component} render={render} />
  );

  render() {
    return (
      <Router>
        <div className="Container" style={bgStyle}>
          <Switch>{routes.map(this.renderRoute)}</Switch>
        </div>
      </Router>
    );
  }
}

export default App;
