import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, notification } from 'antd';
import FormError from '@/components/FormError';
import { LoginCard, LoginSubmit, LoginFormItem } from '@/components/Login';
import Text from '@/components/Text';
import { sendPasswordResetEmail } from '@/services/api';
import Session from '@/services/Session';
import { Link } from 'react-router-dom';
import styles from './index.module.less';

class ForgotPassword extends Component {
  static propTypes = {
    form: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
    formErrors: PropTypes.array,
  };

  static defaultProps = {
    submitting: false,
    formErrors: [],
  };

  state = {
    sent: false,
  };

  handleSuccess = () => {
    this.setState({ sent: true }, () => {
      const { formErrors } = this.props;
      if (formErrors.length === 0) {
        notification.success({
          message: 'Success',
        });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          await sendPasswordResetEmail(values);
          this.handleSuccess();
        } catch (error) {
          console.log('error', error);
        }
      }
    });
  };

  renderFormItems = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    const formItems = [
      {
        name: 'username',
        rules: [
          {
            required: true,
            message: 'Please enter a username',
          },
        ],
        icon: 'user',
        placeholder: 'Username',
      },
    ];

    return formItems.map((itemProps, index) => (
      <LoginFormItem
        {...itemProps}
        key={itemProps.name}
        getFieldDecorator={getFieldDecorator}
        autoFocus={index === 0}
      />
    ));
  };

  render() {
    const { submitting, formErrors } = this.props;
    const { sent } = this.state;

    const { client } = Session.get();

    const email = client.contacts || 'help@keethealth.com';

    return (
      <LoginCard title="Forgot Password" logo={client.logo_uri}>
        {sent && !submitting && formErrors.length === 0 && (
          <Text.Flash>
            You will receive an email with instructions on how to reset your password soon. If you
            don’t receive an email, please confirm that your username is correct or contact{' '}
            <a href={`mailto:${email}`}>{email}</a>.
          </Text.Flash>
        )}
        <Form onSubmit={this.handleSubmit}>
          <FormError formErrors={formErrors} />
          {this.renderFormItems()}
          {/* <Link to="/user/recover_username">
            Forgot Username?
          </Link> */}
          <LoginSubmit loading={submitting}>Send Password</LoginSubmit>
          <div className={styles.backLink}>
            <Link to="/user/login">Back to Login</Link>
          </div>
        </Form>
      </LoginCard>
    );
  }
}

export default Form.create()(ForgotPassword);
