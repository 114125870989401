import { checkStatus } from '@/utils/errors';
import { getAuthenticityToken } from '@/utils';

const MissingChallengeError = new Error('Challenge Missing.');
const baseHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'Content-Type': 'application/json',
  Accept: 'application/json;',
};
const apiVersionHeaders = {
  Accept: 'application/vdn.keethealth.com; version=1',
};

const checkCSRF = () => {
  if (process.env.NODE_ENV === 'development') {
    return null;
  }

  const token = getAuthenticityToken();
  if (!token) {
    /* throw 404 here? */
    throw new Error('Missing Authenticity Token');
  }

  return token;
};

export const getLoginRequest = (challenge) =>
  new Promise(function (resolve, reject) {
    if (!challenge) {
      reject(MissingChallengeError);
    }

    fetch(`/api/login?login_challenge=${challenge}`, {
      headers: {
        ...apiVersionHeaders,
      },
    })
      .then(checkStatus)
      .then((response) => response.json())
      .then((response) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
      .catch(function () {
        /* fail here */
      });
  });

export const acceptLoginRequest = (challenge, credentials) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    if (!challenge) {
      reject(MissingChallengeError);
    }

    fetch(`/api/login?login_challenge=${challenge}`, {
      method: 'post',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
        ...apiVersionHeaders,
      },
      body: JSON.stringify(credentials),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => response.json())
      .then((response) => {
        try {
          const { redirect_to: redirectTo } = response;
          window.location.href = redirectTo;
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const acceptConsentRequest = (challenge) =>
  fetch(`/api/consent?consent_challenge=${challenge}`, {
    headers: {
      ...apiVersionHeaders,
    },
  })
    .then(checkStatus)
    .then((response) => response.json())
    .then((response) => {
      try {
        const { redirect_to: redirectTo } = response;
        window.location.href = redirectTo;
      } catch (e) {
        console.log('there was an error');
      }
    })
    .catch(function () {
      /* fail here */
    });

export const sendPasswordResetEmail = (values) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    fetch('/api/login/forgot_password', {
      method: 'post',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
        ...apiVersionHeaders,
      },
      body: JSON.stringify(values),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return {};
        }

        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const resetPassword = (challenge, credentials) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    fetch(`/api/login/password?id=${challenge}`, {
      method: 'post',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
      },
      body: JSON.stringify(credentials),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return {};
        }

        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const unlock = (challenge) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    fetch(`/api/unlock?id=${challenge}`, {
      method: 'put',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
      },
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return {};
        }

        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const getRegistration = (challenge) =>
  new Promise(function (resolve, reject) {
    if (!challenge) {
      reject(MissingChallengeError);
    }

    fetch(`/api/confirm?id=${challenge}`)
      .then(checkStatus)
      .then((response) => response.json())
      .then((response) => {
        try {
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const completeRegistration = (challenge, credentials) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    if (!challenge) {
      reject(MissingChallengeError);
    }

    fetch(`/api/confirm?id=${challenge}`, {
      method: 'put',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
      },
      body: JSON.stringify(credentials),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return response.text();
        }
        return response.json();
      })
      .then(() => {
        resolve();
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const updateExpiredPassword = (challenge, credentials) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    fetch(`/api/login/password_expired?id=${challenge}`, {
      method: 'put',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
      },
      body: JSON.stringify(credentials),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return {};
        }

        return response.json();
      })
      .then((response) => {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });

export const introspectSession = (session) =>
  new Promise(function (resolve, reject) {
    let authenticityToken;
    try {
      authenticityToken = checkCSRF();
    } catch (e) {
      reject(e);
    }

    if (!session) {
      reject(MissingChallengeError);
    }

    fetch('/api/signature/introspect', {
      method: 'post',
      headers: {
        ...baseHeaders,
        'X-CSRF-Token': authenticityToken,
      },
      body: JSON.stringify({ signature: session }),
      credentials: 'same-origin',
    })
      .then(checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return response.text();
        }
        return response.json();
      })
      .then(resolve)
      .catch(reject);
  });
