import { introspectSession, getLoginRequest, acceptLoginRequest } from './api';

const checkRegistration = (url) => {
  const parseUrl = (uri) => uri && uri.split('?')[1];
  const queryString = parseUrl(url);
  const params = new URLSearchParams(queryString);
  return params.get('CHALLENGEID');
};

const introspectBySignature = async (signature) => {
  const { session } = await introspectSession(signature);
  sessionStorage.setItem('@keethealth/session', btoa(JSON.stringify(session)));
  sessionStorage.setItem('@keethealth/signature', btoa(JSON.stringify(signature)));

  return session;
};

const setSessionDeprecated = async (client, signature) => {
  try {
    const session = await introspectBySignature(signature);
    const { user } = session;

    sessionStorage.setItem('@keethealth/session', btoa(JSON.stringify({ user, client })));
    sessionStorage.removeItem('@keethealth/challenge');
    return { user, client };
  } catch (e) {
    console.log('e', e);
    return {};
  }
};

const introspectByChallenge = async (challenge) => {
  const session = await getLoginRequest(challenge);
  const { client, request_url } = session;

  const registrationToken = checkRegistration(request_url);
  // TODO: Remove later. This is only to support deprecated URL format
  if (registrationToken) {
    const modifiedSession = await setSessionDeprecated(client, registrationToken);
    return { ...modifiedSession, action: 'confirm' };
  }
  sessionStorage.setItem('@keethealth/session', btoa(JSON.stringify({ client })));
  sessionStorage.setItem('@keethealth/challenge', btoa(JSON.stringify(challenge)));
  return session;
};

const handleError = (e) => {
  Session.clear();
  return e;
};

export default class Session {
  static set({ signature, challenge }) {
    return new Promise(async (resolve, reject) => {
      try {
        let session;
        if (signature) {
          session = await introspectBySignature(signature);
        } else {
          session = await introspectByChallenge(challenge);
        }
        resolve(session);
      } catch (e) {
        reject(handleError(e));
      }
    });
  }

  static challenge() {
    try {
      const encodedChallenge = sessionStorage.getItem('@keethealth/challenge');

      return JSON.parse(atob(encodedChallenge));
    } catch (e) {
      return null;
    }
  }

  static signature() {
    try {
      const encodedSignature = sessionStorage.getItem('@keethealth/signature');

      return JSON.parse(atob(encodedSignature));
    } catch (e) {
      return null;
    }
  }

  static get() {
    try {
      const encodedInfo = sessionStorage.getItem('@keethealth/session');
      const { client = {}, ...rest } = JSON.parse(atob(encodedInfo));

      // TODO: Remove once hydra updated
      const clientId = client.client_id || '';

      return {
        client: {
          ...client,
          metadata: clientId.match(/shi-/)
            ? {
                ios_app_id: 'id1220136976',
                android_app_id: 'com.keethealth.patient.shi',
                confirmation_page: {
                  title: 'Get Your Personalized Health Map',
                  subtitle: 'Download the NEW app to:',
                  messages: [
                    'View your progress',
                    'Access your personal health map',
                    'Choose your path to better health',
                  ],
                },
              }
            : {
                ios_app_id: 'id1529474491',
                android_app_id: 'com.keethealth.patient.app',
                confirmation_page: {
                  title: 'Recover on the Go',
                  subtitle: 'Download the NEW patient app to:',
                  messages: [
                    'View your progress',
                    'Access exercises',
                    'Message your therapist',
                    'Set personal reminders',
                    'Keep up with tasks between visits',
                  ],
                },
              },
        },
        ...rest,
      };
    } catch (e) {
      return {
        user: undefined,
        client: undefined,
      };
    }
  }

  static create(credentials) {
    return new Promise(async (resolve, reject) => {
      const challenge = this.challenge();

      try {
        await acceptLoginRequest(challenge, credentials);
      } catch (e) {
        reject(e);
      }
    });
  }

  static clear() {
    sessionStorage.removeItem('@keethealth/session');
    sessionStorage.removeItem('@keethealth/challenge');
    sessionStorage.removeItem('@keethealth/signature');
  }

  static valid() {
    const currentSession = Session.get();
    const challenge = Session.challenge();
    const signature = Session.signature();

    if (signature) {
      return currentSession.user || currentSession.client;
    }

    if (challenge) {
      return currentSession.client;
    }

    return null;
  }
}
