import React, { Component } from 'react';
import { Form, notification, Alert } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { LoginCard, LoginFormItem, LoginSubmit } from '@/components/Login';
import './Login.css';
import Session from '@/services/Session';
import styles from './index.module.less';

class Login extends Component {
  state = {
    redirectTo: undefined,
    error: undefined,
  };

  completeLogin = async (credentials) => {
    this.setState({ error: undefined });

    try {
      await Session.create(credentials);
    } catch (e) {
      if (e.status === 422) {
        const errorResponse = e.response.errors[0];
        if (errorResponse.code === '220') {
          // redirect to new password page
          this.setState({
            redirectTo: {
              pathname: '/user/new_password',
              state: {
                username: credentials.username,
              },
            },
          });
        } else {
          this.setState({
            error: e,
          });
        }
      } else {
        let message;
        if (e.response) {
          message = `Error: ${e.response.errors}`;
        } else {
          message = `Error: ${e.message}`;
        }

        notification.error({ message });
      }
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.completeLogin(values);
      }
    });
  };

  renderFormItems = () => {
    const {
      form: { getFieldDecorator },
    } = this.props;

    const formItems = [
      {
        name: 'username',
        rules: [
          {
            required: true,
            message: 'Please enter a username',
          },
        ],
        icon: 'user',
        placeholder: 'Username',
      },
      {
        name: 'password',
        rules: [
          {
            required: true,
            message: 'Please enter a password',
          },
        ],
        icon: 'lock',
        placeholder: 'Password',
        type: 'password',
      },
    ];

    return formItems.map((itemProps, index) => (
      <LoginFormItem
        {...itemProps}
        key={itemProps.name}
        getFieldDecorator={getFieldDecorator}
        autoFocus={index === 0}
      />
    ));
  };

  renderErrorMessage = (error) => {
    if (error) {
      return (
        <Alert
          className={styles.alert}
          message={error.response.errors.map((message) => (
            <div>{message.detail}</div>
          ))}
          type="error"
          showIcon
        />
      );
    }
    return null;
  };

  render() {
    const { redirectTo } = this.state;
    const response = Session.get();
    const { client } = response;

    if (!Session.valid() || !client) {
      return <Redirect to="/error" />;
    }

    if (redirectTo) {
      return <Redirect to={redirectTo} />;
    }

    const { error } = this.state;

    return (
      <LoginCard title={client.client_name} logo={client.logo_uri}>
        {this.renderErrorMessage(error)}
        <Form onSubmit={this.handleSubmit}>
          {this.renderFormItems()}
          <div>
            <Link to="/user/forgot_password">Forgot Password</Link>
          </div>
          <LoginSubmit loading={false}>Log In</LoginSubmit>
        </Form>
      </LoginCard>
    );
  }
}

export default Form.create()(Login);
