import moment from 'moment';
import _ from 'lodash';

export function formatBirthDate(date) {
  if (!moment(date).isValid()) {
    return 'N/A';
  }
  return moment(date).format('YYYY-MM-DD');
}

export const validateDate = (rule, value, callback) => {
  if (!moment(value).isValid()) {
    callback('The date you entered is not valid');
  } else {
    callback();
  }
};

export const validatePhone = (rule, value, callback) => {
  if (
    !_.isEmpty(value) &&
    value.includes('_') &&
    value !== '(___) ___-____' &&
    value.match(/\d/) !== null
  ) {
    callback('The phone you entered is not valid');
  } else {
    callback();
  }
};

export const getAuthenticityToken = () => {
  try {
    const token = document.getElementsByName('csrf-token')[0].content;
    return token;
  } catch (e) {
    return null;
  }
};

export const getSessionParams = (queryString) => {
  const searchParams = new URLSearchParams(queryString);
  const params = {
    signature: searchParams.get('signature'),
    action: searchParams.get('action') || 'login',
    challenge: searchParams.get('login_challenge'),
  };

  return params;
};
